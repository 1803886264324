import React from 'react';
import createReactClass from 'create-react-class';
import SsoLinks from '../sso_node/sso_links.js';
import SsoHelpers from '../sso_node/sso_helpers.js';
import {Link} from 'react-router-dom';
import {recaptcha} from '../commons.js';
import jQuery from 'jquery';
import PasswordWithConfirmation from '../thewatch/components/shared/password_with_confirmation.js';

window.$ = window.jQuery = jQuery;

const Signup = createReactClass({
  mixins: [SsoHelpers],

  getInitialState: function () {
    return {
      path: '',
      errors: {},
      user: null,
      loading: false,
      loading_get: true,
      plan: [],
      flashes: []
    };
  },

  handleSubmit: function (ev) {
    ev.preventDefault();
    if (!navigator.onLine) {
      this.checkConnection();
      return;
    }

    grecaptcha.execute();
  },

  sendForm: function (token) {
    let data = $(this.refs.form).serializeArray();

    const email = data.find((input) => {
      return input.name === 'user[email]';
    }).value;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailRegex.test(email)) {
      return this.setState({
        errors: {
          email: [I18n.t('activerecord.errors.models.user.attributes.email.invalid_email')]
        }
      });
    }

    data.push({name: 'captcha', value: token});

    return $.ajax({
      url: '/register',
      method: 'POST',
      dataType: 'json',
      data: data,
      beforeSend: (xhr) => {
        this.setState({
          loading: true
        });
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (data) => {
        return this.setState(
          {
            path: data.path
          },
          this.props.handleSuccess(data, 1)
        );
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          return this.setState({
            errors: json_errors.errors,
            loading: false
          });
        }
      }
    });
  },

  componentWillMount: function () {
    recaptcha.mount(this.sendForm);
  },

  componentDidMount: function () {
    return $.ajax({
      cache: false,
      url: location.pathname,
      dataType: 'json',
      data: {
        plan: this.props.plan,
        redirect_to_plans: this.props.redirect_to_plans
      },
      success: (data) => {
        return this.setState({
          user: data.user,
          sso_node: data.sso_node,
          plan: data.plan ? data.plan : void 0,
          loading_get: false,
          flashes: data.flash
        });
      }
    });
  },

  errorsFor: function (namespace) {
    let errors;
    if ((errors = this.state.errors[namespace])) {
      return <p className="inline-errors critical">{errors.join(', ')}</p>;
    }
  },

  render: function () {
    return (
      <div>
        <div className="g-recaptcha" id="recaptcha"></div>
        {!this.state.loading_get && this.state.plan && (
          <SignupDiscountPrice plan={this.props.plan} selected_plan={this.state.plan} />
        )}

        {this.state.flashes &&
          this.state.flashes.map((o, i) => {
            return (
              <p
                key={i}
                className={`login-newui__alert login-newui__alert--warning ${o[0]} information`}
                dangerouslySetInnerHTML={{__html: o[1]}}
              />
            );
          })}

        <h1 className="login-newui__h1">{I18n.t('login.signup')}</h1>
        <div className="sign-form">
          <form className="form form-session" id="new_user" ref="form" method="post" onSubmit={this.handleSubmit}>
            {this.state.plan && <input name="redirect_to" type="hidden" value={this.state.plan.redirect_to} />}

            {this.state.user !== null && (
              <ul className="sign-form hvv">
                <SignupTitle />
                <SignupInputs errorsFor={this.errorsFor} loading={this.state.loading} user={this.state.user} />
                <SignupLinks sso_node={this.state.sso_node} sso_token={this.props.sso_token} />
              </ul>
            )}
          </form>
        </div>
      </div>
    );
  }
});

class SignupNormalLinks extends React.Component {
  render() {
    return (
      <p className="sep">
        <Link className="login-newui__link" to="/login">
          {I18n.t('login.question')}
        </Link>
      </p>
    );
  }
}

class SignupLinks extends React.Component {
  render() {
    return this.props.sso_node === true ? <SsoLinks sso_token={this.props.sso_token} /> : <SignupNormalLinks />;
  }
}

class SignupTitle extends React.Component {
  getTitle = () => {
    return I18n.t('signup.title');
  };

  render() {
    return (
      <li>
        <p className="login-newui__title">{this.getTitle()}</p>
      </li>
    );
  }
}

class SignupInputs extends React.Component {
  state = {
    accepted: {},
    policies: [],
    strongPassword: false
  };

  componentDidMount() {
    return $.ajax({
      url: '/policies/user_terms/blocking_terms.json',
      success: (data) => {
        return this.setState({
          policies: data
        });
      }
    });
  }

  handleCheck = (name, value) => {
    this.state.accepted[name] = value;
    return this.setState({
      accepted: this.state.accepted
    });
  };

  acceptedLength = () => {
    return Object.values(this.state.accepted).filter((o) => {
      return o;
    }).length;
  };

  allChecked = () => {
    return (
      this.state.policies.length > 0 &&
      this.acceptedLength() === this.state.policies.length &&
      this.state.strongPassword
    );
  };

  validStrongPassword = (bool) => {
    this.setState({
      strongPassword: bool
    });
  };

  render() {
    return (
      <div>
        <li className="sep">
          <label className="login-newui__label" htmlFor="user_name">
            {I18n.t('signup.name')}
          </label>
          <input
            tabIndex="1"
            className="login-newui__input"
            autoFocus="autoFocus"
            type="text"
            name="user[name]"
            id="user_name"
            defaultValue={this.props.user.name !== '' ? this.props.user.name : ''}
          />
          {this.props.errorsFor('name')}
        </li>

        <li className="sep">
          <label className="login-newui__label" htmlFor="user_email">
            {I18n.t('signup.email')}
          </label>
          <input
            tabIndex="2"
            className="login-newui__input"
            size="30"
            type="text"
            name="user[email]"
            id="user_email"
            defaultValue={this.props.user.email !== '' ? this.props.user.email : ''}
          />
          {this.props.errorsFor('email')}
        </li>

        <li className="split">
          <ul>
            <PasswordWithConfirmation
              source="signup"
              errorsFor={this.props.errorsFor}
              validStrongPassword={this.validStrongPassword}
            />
          </ul>
        </li>

        <li className="sep form-selects login-newui__checkbox-container">
          {this.state.policies.map((o, i) => {
            return <SignupCheckbox policy={o} key={i} onCheck={this.handleCheck} />;
          })}
        </li>

        <li className="sep">
          <input
            type="submit"
            name="commit"
            value={I18n.t('signup.submit')}
            tabIndex="7"
            disabled={!this.allChecked()}
            className={this.allChecked() ? 'login-newui__button' : 'login-newui__button--disabled'}
            style={
              this.allChecked()
                ? {}
                : {
                    cursor: 'default'
                  }
            }
          />
        </li>
      </div>
    );
  }
}

class SignupCheckbox extends React.Component {
  handleCheck = (e) => {
    return this.props.onCheck(this.props.policy, e.currentTarget.checked);
  };

  render() {
    return (
      <label className="login-newui__checkbox-text">
        <input
          className="login-newui__checkbox"
          ref="checkbox"
          name={`user[policy_rule_${this.props.policy}]`}
          type="checkbox"
          onChange={this.handleCheck}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: I18n.t(`signup.${this.props.policy}`)
          }}
        />
      </label>
    );
  }
}

class SignupDiscountPrice extends React.Component {
  render() {
    return (
      <div className={`chosen-plan plan-${this.props.plan}`}>
        <div className="plan-name">
          {I18n.t('plans.selected_plan', {
            name: this.props.selected_plan.name
          })}
        </div>
        <div className="plan-value">
          ${this.props.selected_plan.price}.0 {I18n.t('plans.usd_month')}
        </div>
        <div className="plan-optional">{I18n.t('plans.anual_discount_desc')}</div>
      </div>
    );
  }
}

export default Signup;
