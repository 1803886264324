import React from 'react';

const NewIcoPrey = ({ height, width, color }) => {
    return (
        <svg width="200" height="80" viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M93.1641 14.0189V25.2481H80.3976V23.6439H91.5683V22.0398H80.3976V14.0189H81.9935V20.4355H91.5683V14.0189H93.1641Z" fill="#1F2938"/>
            <path d="M37.3113 14.0189V25.2479H38.907V22.0396H50.0775V14.0189H37.3113ZM48.4818 20.4355H38.907V15.6232H48.4818V20.4355Z" fill="#1F2938"/>
            <path d="M64.4398 14.0189V15.6232H53.2691V22.0398H51.6734V14.0189H64.4398Z" fill="#1F2938"/>
            <path d="M66.0356 14.0189V22.0396H78.802V20.4355H67.6315V18.8315H78.802V14.0189H66.0356ZM77.2061 17.2272H67.6313V15.6232H77.2061V17.2272Z" fill="#1F2938"/>
            <path d="M19.1344 7.98947L15.2506 10.2436L17.8394 11.746L19.131 10.9948L20.4289 11.7456L23.0172 10.2428L19.1344 7.98947Z" fill="#3B79B9"/>
            <path d="M19.135 31.2775L28.8424 25.6431L28.8422 19.6333L26.2523 21.1359L26.2533 24.1406L19.1338 28.2726L12.0137 24.1398L12.0135 21.1353L9.42558 19.6333V25.6425L19.135 31.2775Z" fill="#3B79B9"/>
            <path d="M30.1363 12.8713V12.8733L30.1381 15.8764L21.0758 21.1363L23.6627 22.6388L19.1342 25.2679L14.6039 22.6388L17.1926 21.1363L8.13027 15.8764L8.13203 12.8751L6.83594 12.1229V8.36741L17.8381 14.7518L19.1342 14.0014L20.4268 14.7518L31.4307 8.36545V12.1209L30.1363 12.8713Z" fill="#1F2938"/>
        </svg>
    );
}

export default NewIcoPrey;
