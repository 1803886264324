import React from 'react';
import SsoHelpers from './sso_helpers.js';
import history from '../thewatch/components/history';
import createReactClass from 'create-react-class';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';

const Setup = createReactClass({
  mixins: [SsoHelpers],

  getInitialState() {
    return {
      logged_in: false,
      email: '',
      setup_version: null,
      sso_token: null,
      loading: true
    };
  },
  componentDidMount() {
    return $.ajax({
      url: 'setup.json',
      success: (res) => {
        return this.setState({
          logged_in: res.logged_in,
          email: res.email ? res.email : null,
          setup_version: res.setup_version,
          sso_token: res.token,
          loading: false,
          availableSlots: res.available_slots
        });
      }
    });
  },

  getSsoToken() {
    if (this.state.sso_token === null) {
      location.href = '/';
      return false;
    } else {
      return this.state.sso_token;
    }
  },
  useCurrentAccount(ev) {
    ev.preventDefault();
    return (location.href = '/auth/configuration/waiting/' + this.getSsoToken());
  },
  goToLogin(ev) {
    ev.preventDefault();
    return history.push('/login/' + this.getSsoToken());
  },
  goToSignup(ev) {
    ev.preventDefault();
    return history.push('/signup/' + this.getSsoToken());
  },
  render() {
    return (
      !this.state.loading && (
        <div className="sign-form">
          <div className="top-icon">
            <figure>
              <NewIcoPrey />
            </figure>
          </div>
          <form className="form form-session">
            <h1 className="login-newui__h1">
              {I18n.t('node_config.configuring_title', {version: this.state.setup_version})}
            </h1>
            {this.state.logged_in && (
              <p className="sep">
                {' '}
                <div className="login-newui__desc">
                  <p className="login-newui__p">{I18n.t('node_config.configuring_desc')}</p>
                  <p
                    className="login-newui__p"
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('node_config.configuring_desc_2', {
                        email: this.state.email,
                        version: this.state.setup_version
                      })
                    }}
                  />
                </div>
                <button className="login-newui__button2" onClick={this.useCurrentAccount}>
                  {I18n.t('node_config.connected_user', {email: this.state.email})}
                </button>
              </p>
            )}

            <div className="login-newui__link-container">
              <p className="sep">
                <a className="login-newui__link" onClick={this.goToLogin} href="#">
                  <span>
                    {this.state.logged_in
                      ? I18n.t('node_config.existing_user.title_2')
                      : I18n.t('node_config.existing_user.title')}
                  </span>
                </a>{' '}
              </p>

              <p className="sep">
                <a className="login-newui__link" onClick={this.goToSignup} href="#">
                  <span>{I18n.t('node_config.new_user.title')}</span>
                </a>{' '}
              </p>
            </div>
          </form>
        </div>
      )
    );
  }
});

export default Setup;
