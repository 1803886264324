import React from 'react';
import actioncable from 'actioncable';
import Typist from 'react-typist';
import SsoHelpers from './sso_helpers.js';
import createReactClass from 'create-react-class';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';

window.App = {
  cable: actioncable.createConsumer()
};

const Waiting = createReactClass({
  mixins: [SsoHelpers],
  getInitialState() {
    return {
      token: null,
      server_error: null,
      event_info: {},
      status: 'connecting',
      network_status: navigator.onLine,
      user_email: null
    };
  },

  componentDidMount() {
    return $.ajax({
      cache: false,
      url: null,
      dataType: 'json',
      success: (data) => {
        if (data.status === 'expired') {
          return (location.href = '/');
        } else {
          return this.setState(
            {
              token: data.token,
              status: data.status,
              os_node: data.os_node,
              verify_email: data.verify_email,
              user_email: data.user_email
            },
            this.subscribe
          );
        }
      }
    });
  },
  checkNetworkStatus() {
    return setInterval(() => {
      return this.setState({
        network_status: navigator.onLine
      });
    }, 2000);
  },
  connected() {
    return this.checkNetworkStatus();
  },
  subscribe() {
    return (App.events = App.cable.subscriptions.create(
      {
        channel: 'ClientConfigurationChannel',
        room: this.state.token
      },
      {
        connected: function () {},
        disconnected: function () {},
        received: function (data) {
          return this.handleMessage(data.message);
        },
        notify: () => {
          return this.perform('notify');
        },
        handleMessage: (event) => {
          console.log('status: ' + event.status);
          if (event.status === 'ok') {
            if (this.state.verify_email) {
              return this.setState({
                event_info: event,
                status: 'success_verify_email'
              });
            } else {
              return this.setState(
                {
                  event_info: event,
                  status: 'success'
                },
                this.redirectToDevice(event.device_key)
              );
            }
          } else {
            return this.setState({
              status: 'client_error'
            });
          }
        }
      }
    ));
  },
  redirectToDevice(device_key) {
    let downloadTimer, timeleft;
    timeleft = 10;
    return (downloadTimer = setInterval(function () {
      timeleft--;
      $('.redirecting span').html('00:0' + timeleft + ':00');
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        location.href = `/devices/${device_key}`;
      }
    }, 1000));
  },
  render() {
    return (
      <div id="fui-load">
        <div className="message">
          <Statuses status={this.state.status} os_node={this.state.os_node} />
        </div>

        {this.state.status === 'success' && (
          <div className="device-info">
            <div className="revealer">
              <h3>{this.state.event_info.hardware.name}</h3>
              <ul className="reveal-meta">
                <li>
                  <p className="reveal-text">
                    {I18n.t('node_config.configured.prey_version')}
                    <b>{this.state.event_info.version}</b>
                  </p>
                </li>
                <li>
                  <p className="reveal-text">
                    {I18n.t('node_config.configured.operating_system')}
                    <b>{this.state.event_info.hardware.os}</b>
                  </p>
                </li>
                {this.state.event_info.hardware.os_version_name && (
                  <li>
                    <p className="reveal-text">
                      {I18n.t('node_config.configured.os_version')}
                      <b>{this.state.event_info.hardware.os_version_name}</b>
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
});

class Statuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }
  // needed for typist to work , just mount and unmount
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps !== this.props.status) {
      return this.setState(
        {
          show: false
        },
        () => {
          return setTimeout(() => {
            this.setState({
              show: true
            });
            return 200;
          });
        }
      );
    }
  }

  render() {
    return this.state.show && <StatusItem {...this.props} />;
  }
}

class StatusItem extends React.Component {
  state = {
    isButtonVisible: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({isButtonVisible: true});
    }, 10000);
  }

  redirectHome(ev) {
    ev.preventDefault();
    return (location.href = '/');
  }

  render() {
    return (
      <div>
        {function () {
          switch (this.props.status) {
            case 'connecting':
              return (
                <div className="device-connected">
                  <div className="top-icon">
                    <figure>
                      <NewIcoPrey />
                    </figure>
                  </div>
                  <div className="loading-text">
                    <h1>{I18n.t('node_config.waiting.connected.title')}</h1>
                    {this.props.user_email && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: I18n.t('node_config.waiting.connected.desc', {email: this.props.user_email})
                        }}
                      ></p>
                    )}
                  </div>
                  <div className="loader-big">
                    <figure className="loader xlarge" />
                  </div>
                  <div className="placeholder_space">
                    {this.state.isButtonVisible && (
                      <div className="loading-text">
                        <p>{I18n.t('node_config.waiting.connected.long_wait')}</p>
                        <button className="login-newui__button2" onClick={this.redirectHome}>
                          <span>{I18n.t('node_config.waiting.connected.button')}</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            case 'disconnected':
              return (
                <div className="device-disconnected">
                  <Typist avgTypingDelay={40}>
                    <h1>{I18n.t('node_config.waiting.disconnected.title')}</h1>
                    <p>{I18n.t('node_config.waiting.disconnected.desc')}</p>
                    <a href="/" className="btn default">
                      {I18n.t('misc.back_to_site')}
                    </a>
                  </Typist>
                </div>
              );
            case 'server_error':
              return (
                <div className="server-error">
                  <Typist avgTypingDelay={40}>
                    <h1>{I18n.t('node_config.waiting.server_error.title')}</h1>
                    <p>{I18n.t('node_config.waiting.server_error.desc')}</p>
                    <code
                      dangerouslySetInnerHTML={{
                        __html: I18n.t(`node_config.waiting.errors_msg.command_${this.props.os_node.toLowerCase()}`)
                      }}
                    ></code>
                    <div className="button-group">
                      <a href="/" className="btn default">
                        {I18n.t('misc.back_to_site')}
                      </a>
                      <a href={I18n.t('node_config.waiting.errors_msg.link')} className="btn">
                        {I18n.t('links.help')}
                      </a>
                    </div>
                  </Typist>
                </div>
              );
            case 'client_error':
              return (
                <div className="client-error">
                  <Typist avgTypingDelay={40}>
                    <h1>{I18n.t('node_config.waiting.client_error.title')}</h1>
                    <p>{I18n.t('node_config.waiting.client_error.desc')}</p>
                    <code
                      dangerouslySetInnerHTML={{
                        __html: I18n.t(`node_config.waiting.errors_msg.command_${this.props.os_node.toLowerCase()}`)
                      }}
                    ></code>
                    <div className="button-group">
                      <a href="/" className="btn default">
                        {I18n.t('misc.back_to_site')}
                      </a>
                      <a href={I18n.t('node_config.waiting.errors_msg.link')} className="btn">
                        {I18n.t('links.help')}
                      </a>
                    </div>
                  </Typist>
                </div>
              );
            case 'success_verify_email':
              return (
                <div className="fui-success">
                  <h2>{I18n.t('signup.title')}</h2>
                  <p>{I18n.t('messages.signup.success_free')}</p>
                  <p>{I18n.t('messages.signup.can_close_window')}</p>
                </div>
              );
            case 'success':
              return (
                <div className="fui-success">
                  <h2>{I18n.t('node_config.waiting.success.title')} </h2>
                  <p> {I18n.t('node_config.waiting.success.desc')}</p>

                  <h3 className="redirecting">
                    <b>{I18n.t('node_config.waiting.success.redirecting')}</b>
                    <span>00:10:00</span>
                  </h3>
                </div>
              );
          }
        }.call(this)}
      </div>
    );
  }
}

export default Waiting;
