import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer className="copy_login-newui__footer">
          <div className="footer-links">
            <a href={I18n.t('footer.terms_url')} target="_blank" className="login-newui__link">
              {I18n.t('links.terms')}
            </a>
            <a href={I18n.t('footer.privacy_url')} target="_blank" className="login-newui__link">
              {I18n.t('links.privacy_policy')}
            </a>
            <a href={I18n.t('footer.cookie_url')} target="_blank" className="login-newui__link">
              Cookies
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
