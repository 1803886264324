import React from 'react';
import {Link} from 'react-router-dom';

class Forgot extends React.Component {
  state = {
    flashes: [],
    redirect: false,
    path: '',
    email: '',
    error: null,
    errors: {},
    loading: false
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    return $.ajax({
      url: '/forgot',
      method: 'POST',
      dataType: 'json',
      data: $(ev.currentTarget).serializeArray(),
      beforeSend: (xhr) => {
        this.setState({
          loading: true
        });
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (data) => {
        let obj = {
          path: data.path
        };
        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;
        return this.setState(obj, () => {
          if (data.path !== '/forgot') {
            return this.props.handleSuccess(data);
          }
        });
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          return this.setState({
            errors: json_errors.errors,
            state: json_errors.errors,
            flashes: json_errors.flash,
            loading: false
          });
        }
      }
    });
  };

  render() {
    return (
      <div className="sign-form">
        <form
          autoComplete="off"
          ref="form"
          className="form form-session"
          id="new-session"
          action="/forgot"
          acceptCharset="UTF-8"
          method="post"
          onSubmit={this.handleSubmit}
        >
          <input name="utf8" type="hidden" value="✓" />

          {this.state.flashes.map((o, i) => {
            return (
              <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
                {o[1]}
              </p>
            );
          })}
          <h1 className="login-newui__h1">{I18n.t('login.forgotten_pass')}</h1>
          <ul className="sign-form">
            {this.state.path === '' || this.state.path === '/forgot' ? (
              <div>
                <li className="sep">
                  <p className="login-newui__title">{I18n.t('forgot.title')}</p>
                </li>

                <li className="sep">
                  <label className="login-newui__label" htmlFor="email">
                    {I18n.t('forgot.label')}
                  </label>
                  <input
                    type="email"
                    ref="email_input"
                    name="email"
                    id="email"
                    tabIndex="1"
                    className="login-newui__input"
                    autoFocus="autoFocus"
                  />
                </li>

                <li className="sep">
                  <input
                    type="submit"
                    name="commit"
                    tabIndex="2"
                    value={this.state.loading ? I18n.t('forgot.submitting') : I18n.t('forgot.submit')}
                    className="login-newui__button"
                    disabled={this.state.loading}
                  />
                </li>
                <div className="login-newui__link-container">
                  <p className="sep">
                    <Link className="login-newui__link" to="/login">
                      {I18n.t('login.link')}
                    </Link>
                  </p>

                  <p className="sep">
                    <Link className="login-newui__link" to="/signup">
                      {I18n.t('signup.question')}
                    </Link>
                  </p>
                </div>
              </div>
            ) : (
              void 0
            )}
          </ul>
        </form>
      </div>
    );
  }
}

export default Forgot;
