import React from 'react';

import SsoHelpers from './sso_helpers.js';
import createReactClass from 'create-react-class';

const SsoLinks = createReactClass({
  mixins: [SsoHelpers],

  render() {
    return (
      <div className="login-newui__link-container">
        <input name="sso_token" type="hidden" value={this.props.sso_token} />

        {this.props.forgot && (
          <p className="sep">
            <a className="login-newui__link" target="_blank" href="/forgot">
              {I18n.t('forgot.question')}
            </a>
          </p>
        )}

        <p className="sep">
          <a className="login-newui__link" href="/auth/configuration/setup">
            {I18n.t('misc.back')}
          </a>
        </p>

        <p className="sep">
          <a className="login-newui__link" href="#" onClick={this.cancelInstalation}>
            {I18n.t('node_config.cancel')}
          </a>
        </p>
      </div>
    );
  }
});

export default SsoLinks;
