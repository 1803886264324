import React from 'react';
import createReactClass from 'create-react-class';
import SsoLinks from '../sso_node/sso_links.js';
import SsoHelpers from '../sso_node/sso_helpers.js';
import {Link} from 'react-router-dom';
import {recaptcha} from '../commons.js';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';
import ButtonNewUI from '../thewatch/new-ui/components/micro-components/button.js';
import {isProduction, isStaging} from '../thewatch/helpers/env.js';

const initialState = {
  flashes: [],
  two_step: false,
  smsRecovery: false,
  smsWaitTime: null,
  smsLimitReached: false,
  smsWaitForNew: false,
  redirect: false,
  path: '',
  email: '',
  password: '',
  error: null,
  errors: {},
  loading_get: true,
  loading: false,
  recovery_code: false,
  sso_node: false,
  show_content: true,
  resend: false,
  poc_token: null,
  poc_unauthorized_disclaimer: null
};

//TODO: remove createReactClass on react.components
const Login = createReactClass({
  mixins: [SsoHelpers],

  getInitialState: function () {
    return initialState;
  },

  componentDidMount: function () {
    if (!recaptcha.requestFromIosClient()) {
      recaptcha.mount(this.sendForm, true);
    }

    const cookieToken = document.cookie.match('(^|;) ?' + 'poc_token' + '=([^;]*)(;|$)');
    if (cookieToken) {
      this.setState({
        poc_token: cookieToken[2]
      });
    }

    const cookiePocDisclaimer = document.cookie.match('(^|;) ?' + 'poc_unauthorized_disclaimer' + '=([^;]*)(;|$)');
    if (cookiePocDisclaimer) {
      this.setState({
        poc_unauthorized_disclaimer: cookiePocDisclaimer[2]
      });
    }

    const cookieEmail = document.cookie.match('(^|;) ?' + 'poc_email' + '=([^;]*)(;|$)');
    if (cookieEmail) {
      let newEmail = cookieEmail[2].replace('%40', '@');
      this.setState({
        email: newEmail,
        path: '/login'
      });
    }

    return this.getLoginInfo();
  },

  errorsFor: function (namespace) {
    let errors;
    if ((errors = this.state.errors[namespace])) {
      return <p className="inline-errors critical" dangerouslySetInnerHTML={{__html: errors.join(', ')}} />;
    }
  },

  handleSubmit: function (event) {
    event.preventDefault();
    if (!navigator.onLine) {
      this.checkConnection();
      return;
    }

    if (this.state.path === '/login') {
      if (recaptcha.requestFromIosClient()) {
        this.sendForm();
      } else {
        grecaptcha.execute();
      }
    } else {
      this.sendForm();
    }
  },

  sendForm: function (token) {
    let data = $(this.refs.form).serializeArray();
    if (token !== '') {
      data.push({name: 'captcha', value: token});
    }

    return $.ajax({
      url: '/session',
      method: 'POST',
      dataType: 'json',
      data: data,
      beforeSend: (xhr) => {
        this.setState({loading: true});
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (data) => {
        let obj = {
          path: data.path
        };

        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.two_step) {
          obj['two_step'] = data.two_step;
        }
        if (data.sms_recovery) {
          obj['smsRecovery'] = data.sms_recovery;
        }
        if (data.saml) {
          location.href = data.path;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;

        const formOnPasswordStep = this.state.password?.length > 0;

        const loginFailed = data.redirect !== true;
        if (grecaptcha && loginFailed && formOnPasswordStep) {
          grecaptcha.reset();
        }

        return this.setState(obj, () => {
          if (data.path === '/login') {
            return this.props.handleSuccess(data, 500);
          }
          this.setState(
            {
              show_content: false
            },
            () => {
              this.props.handleSuccess(data, 500);
            }
          );
        });
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          return this.setState({
            errors: json_errors.errors,
            state: json_errors.errors,
            flashes: json_errors.flash,
            loading: false
          });
        }
      }
    });
  },

  changeAccount: function (event) {
    event.preventDefault();
    this.setState(initialState);
    return this.getLoginInfo();
  },

  displayTwoStepCode: function (event) {
    event.preventDefault();
    return this.setState(
      {
        recovery_code: false
      },
      () => this.refs.two_step_input.focus()
    );
  },

  displayRecoveryCode: function (event) {
    event.preventDefault();
    return this.setState(
      {
        recovery_code: true
      },
      () => this.refs.two_step_input.focus()
    );
  },

  getLoginInfo: function () {
    return $.ajax({
      cache: false,
      url: location.pathname,
      dataType: 'json',
      success: (data) => {
        return this.setState({
          sso_node: data.sso_node,
          flashes: data.flash,
          loading_get: false
        });
      }
    });
  },

  smsNextRequestIn: function () {
    if (!this.state.smsWaitForNew) {
      return;
    }

    let timer = this.state.smsWaitForNew;
    let seconds = 0;
    let interval = setInterval(() => {
      seconds = parseInt(timer % 60, 10);

      this.setState({
        smsWaitTime: I18n.t('messages.sessions.two_step_sms_in_seconds', {count: seconds})
      });

      if (--timer < 0) {
        this.setState({
          smsWaitTime: null
        });
        clearInterval(interval);
      }
    }, 1000);
  },

  requestSmsRecovery: function (event) {
    event.preventDefault();
    $.ajax({
      url: '/request_sms_code',
      method: 'POST',
      dataType: 'json',
      data: {
        email: document.getElementById('email').value
      },
      beforeSend: (xhr) => {
        return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (response) => {
        this.setState(
          {
            flashes: response.flash,
            smsWaitForNew: response.wait_sms
          },
          () => {
            this.smsNextRequestIn();
          }
        );
      },
      error: (err) => {
        let json_errors;
        if ((json_errors = err.responseJSON)) {
          this.setState({
            errors: json_errors.errors,
            state: json_errors.errors,
            flashes: json_errors.flash,
            smsRecovery: true,
            smsLimitReached: true
          });
        }
      }
    });
  },

  handleEmailInput: function (e) {
    this.setState({email: e.target.value});
  },

  handlePasswordInput: function (e) {
    this.setState({password: e.target.value});
  },

  disableLoginButton: function () {
    if (this.state.loading) {
      return true;
    } else if (this.state.path === '') {
      return !this.state.email;
    } else if (this.state.path === '/login') {
      return this.state.password?.length === 0;
    } else {
      return true;
    }
  },

  goToMSP: function () {
    let url;

    if (isProduction()) {
      url = 'https://msp.preyproject.com';
    } else if (isStaging()) {
      url = 'https://msp.preyhq.com';
    } else {
      url = 'http://localhost:4000';
    }

    window.location.href = url;
  },

  render: function () {
    return !this.state.show_content ? (
      <div>
        {this.state.flashes.map((o, i) => {
          return (
            <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
              {o[1]}
            </p>
          );
        })}
      </div>
    ) : (
      <div className="sign-form">
        <div className="top-icon">
          <figure>
            <NewIcoPrey />
          </figure>
        </div>
        <form
          autoComplete="off"
          ref="form"
          className="form form-session"
          id="new-session"
          action="/session"
          acceptCharset="UTF-8"
          onSubmit={this.handleSubmit}
        >
          <input name="utf8" type="hidden" value="✓" />
          <div className="g-recaptcha" id="recaptcha"></div>

          {this.state.flashes.map((o, i) => {
            return (
              <p
                key={i}
                className={`login-newui__alert login-newui__alert--warning ${o[0]} information`}
                dangerouslySetInnerHTML={{__html: o[1]}}
              />
            );
          })}

          <h1 className="login-newui__h1">{I18n.t('login.login')}</h1>
          <ul className="sign-form">
            {this.state.path !== '/' && <li className="sep"></li>}

            {this.state.path === '' && (
              <li className="sep">
                <label className="login-newui__label" htmlFor="email">
                  {I18n.t('login.email')}
                </label>
                <input
                  type="email"
                  ref="email_input"
                  name="email"
                  id="email"
                  tabIndex="1"
                  className="login-newui__input"
                  autoFocus="autoFocus"
                  onChange={this.handleEmailInput}
                />
                <small>{this.errorsFor('email')}</small>
              </li>
            )}

            {this.state.path === '/login' && (
              <div>
                <input type="hidden" name="email" id="email" ref="email" value={this.state.email} />
                <li className="sep">
                  <label className="login-newui__label" htmlFor="password">
                    {I18n.t('login.password')} {$('#email').val()}
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    tabIndex="1"
                    className="login-newui__input"
                    autoFocus="autoFocus"
                    onChange={this.handlePasswordInput}
                  />
                  {this.errorsFor('password')}
                </li>

                {this.state.two_step &&
                  (this.state.recovery_code ? (
                    <li className="sep" style={{marginBottom: '15px'}}>
                      <label className="login-newui__label" htmlFor="recovery_code">
                        {I18n.t('login.recovery_code')}
                      </label>
                      <input
                        type="text"
                        name="recovery_code"
                        id="recovery_code"
                        ref="two_step_input"
                        tabIndex="2"
                        className="login-newui__input"
                        maxLength="16"
                        placeholder={I18n.t('otp.placeholder_1')}
                      />
                      {this.errorsFor('recovery_code')}
                    </li>
                  ) : (
                    <li className="sep" style={{marginBottom: '15px'}}>
                      <label className="login-newui__label" htmlFor="otp_code">
                        {I18n.t('login.two_step')}
                      </label>
                      <input
                        type="text"
                        name="otp_code"
                        id="otp_code"
                        ref="two_step_input"
                        tabIndex="2"
                        className="login-newui__input"
                        maxLength="6"
                        placeholder={I18n.t('otp.placeholder_2')}
                      />
                      {this.errorsFor('otp_code')}
                    </li>
                  ))}

                {this.state.two_step &&
                  (this.state.recovery_code ? (
                    <li className="sep">
                      <a className="login-newui__link" href="#" onClick={this.displayTwoStepCode}>
                        <i className="icon-alert-sign"></i>
                        &nbsp;
                        {I18n.t('otp.link_2')}
                      </a>
                    </li>
                  ) : (
                    <li className="sep">
                      <a className="login-newui__link" href="#" onClick={this.displayRecoveryCode}>
                        <i className="icon-alert-sign"></i>
                        &nbsp;
                        {I18n.t('otp.link_1')}
                      </a>
                    </li>
                  ))}

                {this.state.two_step &&
                  this.state.recovery_code &&
                  this.state.smsRecovery &&
                  !this.state.smsLimitReached && (
                    <li className="sep">
                      {this.state.smsWaitTime ? (
                        <p className="btn-block">
                          <i className="icon-alert-sign"></i>
                          &nbsp;
                          {this.state.smsWaitTime}
                        </p>
                      ) : (
                        <a className="login-newui__link" href="#" onClick={this.requestSmsRecovery}>
                          <i className="icon-alert-sign"></i>
                          &nbsp;
                          {I18n.t('otp.link_3')}
                        </a>
                      )}
                    </li>
                  )}
              </div>
            )}

            {this.state.path !== '/' && (
              <div id="loginButtons">
                {this.state.path === '/login' && (
                  <a href={null} onClick={this.changeAccount} className="login-newui__secondary-button">
                    {I18n.t('login.change_account')}
                  </a>
                )}
                <button className="login-newui__button" disabled={this.disableLoginButton()}>
                  {this.state.loading ? (
                    <div className="loader-container-btn">
                      <i className="loader small"></i>
                      <span>{I18n.t('login.submit')}</span>
                    </div>
                  ) : (
                    I18n.t('login.submit')
                  )}
                </button>
              </div>
            )}

            {!this.state.loading_get &&
              (this.state.sso_node ? (
                <SsoLinks forgot={true} sso_token={this.props.sso_token} />
              ) : (
                <div className="login-newui__link-container">
                  <ButtonNewUI
                    type="secondary"
                    content={I18n.t('login.msp_login')}
                    action={() => this.goToMSP()}
                    additionalClass="login-newui__msp-button"
                  />
                  <p className="sep">
                    <Link className="login-newui__link" to="/forgot">
                      {I18n.t('forgot.question')}
                    </Link>
                  </p>

                  <p className="sep">
                    <Link className="login-newui__link" to="/signup">
                      {I18n.t('signup.question')}
                    </Link>
                  </p>

                  <div className="login-newui__activation-code-container">
                    <p>
                      <Link className="login-newui__link" to="/resend">
                        {' '}
                        {I18n.t('messages.signup.resend.text')}
                      </Link>
                    </p>
                  </div>
                </div>
              ))}
          </ul>
        </form>
      </div>
    );
  }
});

const SlowInternetDisclaimer = ({redirectFn}) => {
  return (
    <div className="connectivity-issues">
      <i className="icon-activity" />
      <h3>{I18n.t('login.slow_internet_disclaimer.body')}</h3>
      <button className="btn btn-primary" onClick={() => redirectFn && redirectFn()}>
        {I18n.t('login.slow_internet_disclaimer.cta')}
      </button>
    </div>
  );
};

export default Login;
