import React from 'react';
import SsoHelpers from './sso_helpers.js';
import Footer from '../credentials/footer.js';
import createReactClass from 'create-react-class';

const Wizard = createReactClass({
  mixins: [SsoHelpers],
  getInitialState() {
    return {
      step: 1
    };
  },
  componentDidMount() {
    this.props.listenPath(this.updateWizard);
    if (document.location.href === document.referrer || document.location.href.match('callback')) {
      return this.updateWizard(2);
    }
  },
  updateWizard(step) {
    return this.setState({
      step: step
    });
  },
  renderStep(selectedStep = 1) {
    return (
      <div>
        <div className={`step-marker ${selectedStep === 1 ? 'selected' : ''}`}>
          <div className="desc">
            <h1>
              <span className="num">1</span>
              {I18n.t('node_config.steps.one.title')}
            </h1>
            {selectedStep === 1 && (
              <div>
                <h2>{I18n.t('node_config.steps.one.desc')}</h2>
                <p>{I18n.t('node_config.steps.one.desc_2')} </p>
              </div>
            )}
          </div>
        </div>
        <div className={`step-marker ${selectedStep === 2 ? 'selected' : ''}  `}>
          <div className="desc">
            <h1>
              <span className="num">2</span>
              {I18n.t('node_config.steps.two.title')}
            </h1>
            {selectedStep === 2 &&
              ((<h2>{I18n.t('node_config.steps.two.desc')}</h2>), (<p>{I18n.t('node_config.steps.two.desc_2')}</p>))}
          </div>
        </div>
      </div>
    );
  },
  render() {
    return (
      <div>
        <div className="hidden" id="network-alert">
          <div className="message">
            <p className="title" />
            <p className="content" />
          </div>
        </div>

        <div className="node" id="sso-login">
          <div className="button-group">
            <div className="layout">
              <h1 className="main-title">{I18n.t('node_config.title')}</h1>
              <div id="appRender" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
});

export default Wizard;
