const SsoHelpers = {
  cancelInstalation: function (ev) {
    ev.preventDefault();
    if (confirm(I18n.t('forms.confirm'))) {
      return (window.location.href = '/auth/configuration/cancel');
    }
  },
  checkConnection: function () {
    let interval = null;
    if (!navigator.onLine) {
      $('#network-alert').removeClass('hidden');
      $('#network-alert .message .title').html(I18n.t('network_offline.title'));
      $('#network-alert .message .content').html(I18n.t('network_offline.desc'));
      interval = setInterval(function () {
        if (navigator.onLine) {
          $('#network-alert').addClass('hidden');
          clearInterval(interval);
        }
      }, 300);
      return false;
    }
  },
  userLang: function () {
    let key, keyValue;
    key = 'lang';
    keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    if (keyValue) {
      return unescape(keyValue[2]);
    } else {
      return null;
    }
  }
};

export default SsoHelpers;
