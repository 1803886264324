import React from 'react';
import {default as Typist} from 'react-typist';

export default class Configured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
      loading: true
    };
  }

  componentDidMount() {
    return $.ajax({
      url: 'configured.json',
      success: (res) => {
        return this.setState({
          device: res.device,
          loading: false
        });
      }
    });
  }

  render() {
    return (
      <div id="fui-load">
        <div className="message">
          <div className="fui-success">
            <h2 className="login-newui__h2">{I18n.t('node_config.configured.title')}</h2>
            <a href="/" className="login-newui__link">
              {I18n.t('misc.back_to_panel')}
            </a>
          </div>
        </div>

        {!this.state.loading && (
          <Typist>
            <div className="device-info">
              <div className="revealer">
                <small>{I18n.t('node_config.waiting.success.device_info')}</small>
                <h3>{this.state.device.name}</h3>
                <ul className="reveal-meta">
                  <li>
                    <p className="reveal-text">
                      {I18n.t('node_config.configured.prey_version')}
                      <b>{this.state.device.client_version}</b>
                    </p>
                  </li>
                  <li>
                    <p className="reveal-text">
                      {I18n.t('node_config.configured.operating_system')}
                      <b>{this.state.device.os}</b>
                    </p>
                  </li>
                  <li>
                    <p className="reveal-text">
                      {I18n.t('node_config.configured.os_version')}
                      <b>{this.state.device.os_version_name}</b>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </Typist>
        )}
      </div>
    );
  }
}
